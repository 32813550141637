import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/cryptowander/Maker/test/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`MIP0c12-SP1: Subproposal for Core Personnel Onboarding (MIP Editor)`}</h1>
    <h2>{`Preamble`}</h2>
    <pre><code parentName="pre" {...{}}>{`MIP0c12-SP#: 1
Author: Charles St.Louis
Contributors: n/a
Status: Request for Comments (RFC)
Date Applied: 2020-04-22
Date Ratified: <yyyy-mm-dd>
---
Core Personnel Role: MIP Editor
Proposed applicant: Charles St.Louis
`}</code></pre>
    <h2>{`Application Form`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Motivation`}</strong><ul parentName="li">
          <li parentName="ul">{`The Maker Improvement Proposals Framework requires a MIP Editor when the framework becomes ratified. Given that I am closest to the process and am driven towards making the Protocol self-sustainable, I am propsing myself to take on the MIP Editor role. As the MIP Editor, I will support and enforce the administrative and editorial aspects of the overall MIPs process and framework. For the full list of resposibilities, please reference `}<a parentName="li" {...{
              "href": "https://github.com/makerdao/mips/blob/master/MIP0/mip0.md#mip0c10-mip-editor-role"
            }}><inlineCode parentName="a">{`MIP0c10: MIP Editor Role`}</inlineCode></a>{`.`}</li>
          <li parentName="ul">{`Additioanlly, as one of the architects of the framework, I will actively work with the community to help sheppard incoming MIPs and work and communicate with Governance Facilitators on coordinating governance and executive votes in relation to MIPs and the monthly governance cycle.`}</li>
          <li parentName="ul">{`Lastly, I would like to note that the expectation is that the MIPs Framework will start out with a MIP Editor from the Maker Foundation (myself) and that other MIP Editors will join later.`}</li>
        </ul></li>
      <li parentName="ul"><strong parentName="li">{`Credentials`}</strong><ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Work Experience`}</strong><ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`Current:`}</strong>{` `}<ul parentName="li">
                  <li parentName="ul"><strong parentName="li">{`Decentralized Governance Architect @ Maker Foundation`}</strong><ul parentName="li">
                      <li parentName="ul">{`Responsible for the initiative to further research, architect, and formalize Maker Governance processes on behalf of the Maker Foundation (`}<a parentName="li" {...{
                          "href": "https://forum.makerdao.com/t/the-maker-foundation-s-vision-of-a-self-sustaining-makerdao-initiation-of-maker-improvement-proposals-mips-framework/1882"
                        }}>{`Self-Sustaining Maker`}</a>{`).`}</li>
                      <li parentName="ul">{`Other relevant `}<strong parentName="li">{`public`}</strong>{` work: `}<ul parentName="li">
                          <li parentName="ul"><a parentName="li" {...{
                              "href": "http://docs.makerdao.com/"
                            }}>{`docs.makerdao.com`}</a></li>
                          <li parentName="ul">{`Contributor `}<a parentName="li" {...{
                              "href": "https://blog.makerdao.com/"
                            }}>{`blog.makerdao.com`}</a></li>
                          <li parentName="ul">{`Contributor `}<a parentName="li" {...{
                              "href": "https://makerdao.com/en/whitepaper"
                            }}>{`MCD Whitepaper`}</a>{` `}</li>
                          <li parentName="ul">{`Contributor `}<a parentName="li" {...{
                              "href": "https://github.com/makerdao/developerguides"
                            }}>{`Developer Guides`}</a>{` `}</li>
                          <li parentName="ul"><a parentName="li" {...{
                              "href": "http://security.makerdoa.com/"
                            }}>{`security.makerdao.com`}</a></li>
                        </ul></li>
                    </ul></li>
                  <li parentName="ul"><strong parentName="li">{`PM @ Ethereum Cat Herders`}</strong>{` (Volunteer / Community Contributor) `}<ul parentName="li">
                      <li parentName="ul">{`This includes helping out with technical audit coordination, advisor/editor of Ethereum.org, developer growth initiatives, creating PM processes and retroactive reports, and relaying information between Ethereum Core Dev teams.`}</li>
                    </ul></li>
                </ul></li>
              <li parentName="ul"><strong parentName="li">{`Past:`}</strong>{` `}<ul parentName="li">
                  <li parentName="ul">{`Independent technical consultant and advisor`}</li>
                  <li parentName="ul">{`Developer Experience Lead @ Polymath Network`}</li>
                </ul></li>
            </ul></li>
        </ul></li>
    </ul>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Relevant Information`}</strong><ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Github Account:`}</strong>{` `}<a parentName="li" {...{
              "href": "https://github.com/CPSTL"
            }}>{`@CPSTL`}</a></li>
          <li parentName="ul"><strong parentName="li">{`MakerDAO Forum Account:`}</strong>{` `}<a parentName="li" {...{
              "href": "https://forum.makerdao.com/u/charlesstlouis/summary"
            }}>{`@charlesstlouis`}</a></li>
          <li parentName="ul"><strong parentName="li">{`Twitter Account:`}</strong>{` `}<a parentName="li" {...{
              "href": "https://twitter.com/CharlieStLouis"
            }}>{`@CharlieStLouis`}</a></li>
        </ul></li>
    </ul>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      